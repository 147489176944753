import { getFileExtension } from "@moe/oss/utils/utils";
import { config } from "@moe/priv/config";
import { characterConfig } from "@moe/priv/model/character";
import { sb } from "@web/lib/supabase";
import { v4 } from "uuid";

// Helper function to extract and normalize tags from text
export function extractTags(text: string): string[] {
  // Match hashtag followed by any characters until a space or another hashtag
  // This regex looks for:
  // #        - a hash symbol
  // (?:      - start non-capturing group
  //   [^\s#] - any character that's not a whitespace or #
  //   +      - one or more times
  // )        - end group
  const tagRegex = /#([^\s#]+)/g;
  const matches = text.matchAll(tagRegex);
  // k: lowercase representation of tag,
  // v: original tag
  const tags: Map<string, string> = new Map();

  // Clean tags and add them to the map
  for (const match of matches) {
    const cleaned = match[1].replace(characterConfig.tagsCleanRegex, "").trim();
    if (cleaned.length > 0) tags.set(cleaned.toLowerCase(), cleaned);
  }

  // Return unique array of tags
  return Array.from(tags.values());
}

export async function uploadImage(file: File): Promise<string> {
  const ext = getFileExtension(file.name);
  const path = `${v4()}.${ext}`;
  const { data, error } = await sb.storage.from(config.storage.publicImagesBucket).upload(path, file);
  if (error) throw error;
  return data.path;
}

/**
 * Uploads a file to the transfer bucket.
 * The backend will then download the file and deletes it.
 * This is a workaround, TRPC does not support file uploads.
 */
export async function transferFile(file: File) {
  const ext = getFileExtension(file.name);
  const path = `${v4()}.${ext}`;
  const { data, error } = await sb.storage.from(config.storage.transferBucket).upload(path, file);
  if (error) throw error;
  return data.path;
}

export async function removeImage(fileName: string): Promise<void> {
  const { error } = await sb.storage.from(config.storage.publicImagesBucket).remove([fileName]);
  if (error) throw error;
}

// Function to format large numbers
export function formatCount(count: number) {
  if (count >= 1000000) {
    return `${(count / 1000000).toFixed(1)}M`;
  } else if (count >= 1000) {
    return `${(count / 1000).toFixed(1)}K`;
  } else {
    return count.toString();
  }
}

/**
 * Converts a File object to a base64 string.
 * @param file - The File object to convert
 * @returns Promise that resolves with the base64 string
 * @throws Error if file reading fails
 */
export const fileToB64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    // Create a new FileReader instance
    const reader = new FileReader();

    // Set up the onload handler
    reader.onload = () => {
      // Make sure we have a result
      if (typeof reader.result === "string") {
        // Extract the base64 data (remove the data URL prefix)
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      } else {
        reject(new Error("FileReader result is not a string"));
      }
    };

    // Set up error handler
    reader.onerror = () => {
      reject(new Error("Error reading file"));
    };

    // Start reading the file
    reader.readAsDataURL(file);
  });
};

/**
 * The desktop media query we often use is dynamic / reactive, it changes based on the window size.
 * This static variable is determined only only once onload, it belongs in TS land, not React land.
 */
export const isDesktopStatic = window.innerWidth >= 768;
