import { CosmeticSettings, cosmeticCSSVarMap, cosmeticQK } from "@web/route-services/chat/CosmeticSettingsModal";
import { fontScaleQK } from "@web/routes/settings/account";
import { useEffect } from "react";

/**
 * A hook that applies users font scale and appearance preferences to the document on mount.
 */
export function useApplyUserPreference() {
  useEffect(() => {
    const cached = localStorage.getItem(fontScaleQK);
    if (cached) document.documentElement.style.setProperty("--font-scale", cached.toString());
  }, []);

  useEffect(() => {
    const cached = localStorage.getItem(cosmeticQK);
    if (!cached) return;

    const preferences: CosmeticSettings = JSON.parse(cached);
    Object.entries(preferences).forEach(([key, value]) => {
      const cssVar = cosmeticCSSVarMap[key as keyof CosmeticSettings];
      document.documentElement.style.setProperty(cssVar, value);
    });
  }, []);
}
