import { config } from "@moe/priv/config";
import { Character, characterConfig, CharacterInsert, CharacterUpdate } from "@moe/priv/model/character";
import { TablesInsert, TablesUpdate } from "@moe/priv/types/sb-types";
import { CharacterService } from "@moe/priv/types/types";
import { removeHashtags } from "@moe/priv/utils";
import { QueryData } from "@supabase/supabase-js";
import { ID } from "@web/accessor/base";
import { sb } from "@web/lib/supabase";
import { Client } from "@web/lib/trpc";
import { extractTags, removeImage, uploadImage } from "@web/lib/utils";
import { DeepPartial } from "react-hook-form";

type R = Character;
type I = CharacterInsert;
type U = CharacterUpdate;

type GetPageOptions = CharacterService.GetPageOptions;
type GetLastPageOptions = CharacterService.GetLastPageOptions;
type UpdateFormCharacter = CharacterService.UpdateFormCharacter;

namespace Q {
  export namespace All {
    export const selector = `
    id, name, greeting, alternative_greetings, avatar_file_name, banner_file_name, 
    is_nsfw, is_forced_nsfw, is_private, profiles!characters_created_by_fkey(username), created_at, updated_at,
    title, tagline, total_like_count, total_message_count, tag_ids,
    external_created_by, characters_likes(profile_id), deleted_at` as const;
    const builder = sb.from("characters").select(selector);
    export type Data = QueryData<typeof builder>[number];
  }
  export namespace AllGuest {
    export const selector = `
    id, name, greeting, alternative_greetings, avatar_file_name, banner_file_name, 
    is_nsfw, is_forced_nsfw, is_private, profiles!characters_created_by_fkey(username), created_at, updated_at,
    title, tagline, total_like_count, total_message_count, tag_ids,
    external_created_by, deleted_at` as const;
    const builder = sb.from("characters").select(selector);
    export type Data = QueryData<typeof builder>[number];
  }
  export namespace Count {
    export const selector = [`id`, { count: "planned", head: true }] as const;
    const builder = sb.from("characters").select(...selector);
    export type Builder = typeof builder;
  }
}

interface Constructor {
  userID?: string;
  trpc: Client;
}
export class CharacterAccessor {
  private userID?: string;
  private trpc: Client;

  constructor({ userID, trpc }: Constructor) {
    this.userID = userID;
    this.trpc = trpc;
  }

  async getSelf(): Promise<R[]> {
    if (!this.userID) {
      throw new Error("User is not authenticated while attempting to get self owned characters");
    }
    const q = sb
      .from("characters")
      .select(Q.All.selector)
      .eq("created_by", this.userID)
      .eq("characters_likes.profile_id", this.userID);

    const { data, error } = await q;
    if (error) throw error;

    return this.processAllQuery(data);
  }

  /**
   * Get all characters belong to a user.
   * All deleted characters are filtered out.
   */
  async getByUsername(username: string): Promise<R[]> {
    const q = sb.from("characters");
    let res;
    if (this.userID) {
      res = await q
        .select(Q.All.selector)
        .eq("characters_likes.profile_id", this.userID)
        .eq("profiles.username", username)
        .is("deleted_at", null)
        .order("created_at", { ascending: false });
    } else {
      res = await q
        .select(Q.AllGuest.selector)
        .eq("profiles.username", username)
        .order("created_at", { ascending: false });
    }
    const { data, error } = res;
    if (error) throw error;
    return this.processAllQuery(data);
  }

  async getByID(id: ID): Promise<R>;
  async getByID(id: ID[]): Promise<R[]>;
  async getByID(id: ID | ID[]): Promise<R | R[]>;
  async getByID(id: ID | ID[]): Promise<R | R[]> {
    const q = sb.from("characters").select(Q.All.selector);
    if (Array.isArray(id)) {
      const { data, error } = await q.in("id", id);
      if (error) throw error;
      return this.processAllQuery(data);
    }
    const { data, error } = await q.eq("id", id).single();
    if (error) throw error;
    return this.processAllQuery(data);
  }

  async create(value: I): Promise<number>;
  async create(value: I[]): Promise<number[]>;
  async create(value: I | I[]): Promise<number | number[]>;
  async create(value: I | I[]): Promise<number | number[]> {
    if (!this.userID) throw new Error("User is not authenticated while attempting to create characters");

    if (Array.isArray(value)) return Promise.all(value.map((v) => this.create(v)));
    const avatar = value.avatar;
    const banner = value.banner;

    const avatarFileName = await uploadImage(avatar);
    let bannerFileName: string | undefined;
    if (banner) bannerFileName = await uploadImage(banner);

    let tags: string[] = [];
    // Add tags from tagline
    if (value.tagline) tags = extractTags(value.tagline).slice(0, characterConfig.tagsMaxCount);

    // Convert to array and get IDs
    const tagIDs = await this.getOrCreateTags(tags);

    const tagsCSV = value.tags?.join(",") ?? "";
    const insertData: TablesInsert<"characters"> = {
      name: value.name,
      title: value.title,
      tagline: value.tagline,
      tags: tagsCSV,
      tag_ids: tagIDs,
      external_created_by: value.externalCreatedBy,
      external_kind: value.externalKind,
      message_examples: value.messageExamples,
      description: value.description,
      greeting: value.greeting,
      alternative_greetings: value.alternativeGreetings?.filter((a) => a !== ""),
      definitions: value.definitions,
      avatar_file_name: avatarFileName,
      banner_file_name: bannerFileName,
      is_nsfw: value.isNSFW,
      is_private: value.isPrivate
    };
    const { data, error } = await sb.from("characters").insert(insertData).select(Q.All.selector).single();
    if (error) throw error;
    return data.id;
  }

  async update(value: U | U[]): Promise<void> {
    if (!this.userID) throw new Error("User is not authenticated while attempting to create characters");
    if (Array.isArray(value)) {
      Promise.all(value.map(async (v) => this.update(v)));
      return;
    }
    const { data: old, error: oldError } = await sb
      .from("characters")
      .select("avatar_file_name, banner_file_name")
      .eq("id", value.id)
      .single();
    if (oldError) throw oldError;

    const avatar = value.avatar;
    const banner = value.banner;
    let avatarFileName: string | undefined;
    let bannerFileName: string | undefined;
    if (avatar) avatarFileName = await uploadImage(avatar);
    if (banner) bannerFileName = await uploadImage(banner);

    // Add tags from tagline to the tag_ids column
    let tags: string[] = [];
    if (value.tagline) tags = extractTags(value.tagline).slice(0, characterConfig.tagsMaxCount);

    const tagIDs = await this.getOrCreateTags(tags);
    const updateValue: TablesUpdate<"characters"> = {
      name: value.name,
      title: value.title,
      tagline: value.tagline,
      tag_ids: tagIDs,
      description: value.description,
      greeting: value.greeting,
      alternative_greetings: value.alternativeGreetings?.filter((a) => a !== ""),
      definitions: value.definitions,
      message_examples: value.messageExamples,
      avatar_file_name: avatarFileName,
      banner_file_name: bannerFileName,
      is_nsfw: value.isNSFW,
      is_private: value.isPrivate,
      deleted_at: value.deletedAt
    };

    const { error } = await sb.from("characters").update(updateValue).eq("id", value.id).single();
    if (error) throw error;

    // Remove old files if they were updated
    if (old.avatar_file_name && avatarFileName) await removeImage(old.avatar_file_name);
    if (old.banner_file_name && bannerFileName) await removeImage(old.banner_file_name);
  }

  /**
   * Soft deletes a character.
   */
  async delete(id: number): Promise<void>;
  async delete(id: number[]): Promise<void>;
  async delete(id: number | number[]): Promise<void> {
    if (!this.userID) throw new Error("User is not authenticated while attempting to delete characters.");
    if (Array.isArray(id)) {
      await Promise.all(id.map((i) => this.delete(i)));
      return;
    }
    await this.update({ id, deletedAt: new Date().toISOString() });
    return;
  }

  /**
   * Get all characters that are in a chat.
   */
  async getByChatID(id: number): Promise<R[]> {
    if (!this.userID) throw new Error("User must be authenticated to get characters by chat ID");
    const { data, error } = await sb
      .from("chats_characters")
      .select(`characters (${Q.All.selector})`)
      .eq("chat_id", id)
      .eq("characters.characters_likes.profile_id", this.userID);
    if (error) throw error;

    if (!data) throw new Error("No characters found in chat");
    const characters = data.map((item) => item.characters).filter((item) => !!item);
    return this.processAllQuery(characters);
  }

  /**
   * Retrieves a list of featured characters.
   * Deleted characters are filtered out.
   */
  async getFeatured(nsfwOK?: boolean): Promise<R[]> {
    let q;
    if (this.userID) {
      q = sb
        .from("featured")
        .select(`characters(${Q.All.selector})`)
        .eq("characters.characters_likes.profile_id", this.userID);
    } else {
      q = sb.from("featured").select(`characters(${Q.AllGuest.selector})`);
    }
    q.order("created_at", { ascending: false });

    if (!nsfwOK) {
      q.eq("characters.is_nsfw", false);
      q.eq("characters.is_forced_nsfw", false);
    }

    const { data, error } = await q;
    if (error) throw error;
    const mapped = data.flatMap((d) => d.characters || []);
    return this.processAllQuery(mapped);
  }

  async like(id: number): Promise<void> {
    if (!this.userID) throw new Error("User is not authenticated while attempting to like character");
    const likeValue: TablesInsert<"characters_likes"> = {
      character_id: id,
      profile_id: this.userID
    };
    const { error } = await sb.from("characters_likes").insert(likeValue);
    if (error) throw error;
  }

  async unlike(id: number): Promise<void> {
    if (!this.userID) throw new Error("User is not authenticated while attempting to unlike character");
    const { error } = await sb.from("characters_likes").delete().eq("character_id", id).eq("profile_id", this.userID);
    if (error) throw error;
  }

  private normalizeGetPageOptions(options?: DeepPartial<GetPageOptions>): GetPageOptions {
    const tags: string[] = options?.filter?.tags?.filter((t): t is string => !!t) ?? [];
    return {
      page: options?.page ?? 0,
      pageSize: options?.pageSize ?? 30,
      ranking: options?.ranking ?? "hotness",
      order: options?.order ?? "desc",
      filter: {
        period: options?.filter?.period ?? "all",
        nsfwOK: options?.filter?.nsfwOK ?? false,
        privateOK: options?.filter?.privateOK ?? false,
        searchText: options?.filter?.searchText ?? "",
        creator: options?.filter?.creator,
        externalCreator: options?.filter?.externalCreator,
        tags
      }
    };
  }
  async getForUpdate(id: number): Promise<UpdateFormCharacter> {
    return await this.trpc.character.getForUpdate.query(id);
  }

  /**
   * @outdated This should be placed in the trpc backend instead.
   * Get the last page number for the explore page pagination.
   * Private and deleted characters are filtered out.
   */
  async getLastPageNumber(options?: DeepPartial<GetLastPageOptions>): Promise<number> {
    const opt = this.normalizeGetPageOptions(options) as GetLastPageOptions;
    const q = sb
      .from("characters")
      .select(...Q.Count.selector)
      .is("is_private", false)
      .is("deleted_at", null);

    if (!opt.filter.nsfwOK) q.is("is_nsfw", false);

    const searchText = opt.filter.searchText;
    if (searchText) {
      q.or(`title.ilike.%${searchText}%,tagline.ilike.%${searchText}%,name.ilike.%${searchText}%`);
    }

    const { count, error } = await q;
    if (error) throw error;

    // Count is inexact so we subtract 1 to be safe
    return Math.ceil((count || 0) / opt.pageSize) - 1;
  }

  async getPage(options?: DeepPartial<GetPageOptions>): Promise<R[]> {
    const opt = this.normalizeGetPageOptions(options);
    return await this.trpc.character.getPage.query(opt);
  }

  /**
   * Get *all* tags from the db.
   * Very expensive operation, please cache this if you use it.
   */
  async getTags(): Promise<string[]> {
    return await this.trpc.character.getAllTags.query();
  }

  /**
   * Get all ids of the given tags, or create then get their id them if they don't exist yet.
   *
   * @param tags An array of tags to get or create.
   * @returns The tags and their accomplying id.
   */
  private async getOrCreateTags(tags: string[]): Promise<number[]> {
    if (tags.length === 0) return [];
    // First, try to get all existing tags in one query
    const { data: existingTags, error: findError } = await sb.from("tags").select("id, name").in("name", tags);
    if (findError) throw findError;

    // Create a map of normalized -> id for existing tags
    const existingTagMap = new Map(existingTags?.map((tag) => [tag.name.toLowerCase(), tag.id]) || []);

    // Find which tags need to be created
    const tagsToCreate = tags.filter((tag) => !existingTagMap.has(tag.toLowerCase()));

    // If there are no tags to be created, we're done, just return the IDs
    if (tagsToCreate.length === 0) return existingTags.map((tag) => tag.id);

    // Create all new tags in one query
    const { data: newTags, error: insertError } = await sb
      .from("tags")
      .insert(
        tagsToCreate.map((tag) => ({
          name: tag
        }))
      )
      .select("id");
    if (insertError) throw insertError;

    const uniqueIDs = new Set([...existingTags, ...newTags].map((tag) => tag.id));
    return Array.from(uniqueIDs);
  }

  private async processAllQuery(value: Q.All.Data[] | Q.AllGuest.Data[]): Promise<R[]>;
  private async processAllQuery(value: Q.All.Data | Q.AllGuest.Data): Promise<R>;
  private async processAllQuery(
    value: Q.All.Data | Q.All.Data[] | Q.AllGuest.Data | Q.AllGuest.Data[]
  ): Promise<R | R[]>;
  private async processAllQuery(
    value: Q.All.Data | Q.All.Data[] | Q.AllGuest.Data | Q.AllGuest.Data[]
  ): Promise<R | R[]> {
    // Shared function between the many and the one case
    const getTagMap = async (tagIDs: number[]) => {
      const { data, error } = await sb
        .from("tags")
        .select("id, name")
        .in("id", tagIDs)
        .order("popularity", { ascending: false })
        .limit(characterConfig.tagsMaxCount);
      if (error) throw error;
      const map = new Map<number, string>();
      for (const t of data) map.set(t.id, t.name);
      return map;
    };

    // Shared function between the many case and the one case to process query data
    const process = (tagMap: Map<number, string>, row: Q.All.Data | Q.AllGuest.Data): R => {
      let banner: string | undefined;
      const avatar = sb.storage.from(config.storage.publicImagesBucket).getPublicUrl(row.avatar_file_name)
        .data.publicUrl;

      if (row.banner_file_name) {
        banner = sb.storage.from(config.storage.publicImagesBucket).getPublicUrl(row.banner_file_name).data.publicUrl;
      }

      let isLiked;
      // If there's a characters_likes field, check if user has liked the character
      if ("characters_likes" in row) {
        isLiked = row.characters_likes.some((l) => l.profile_id === this.userID);
      } else {
        isLiked = false;
      }

      const tags = row.tag_ids.map((id) => tagMap.get(id)).filter((t): t is string => !!t);
      return {
        id: row.id,
        title: row.title,
        tagline: removeHashtags(row.tagline),
        tags,
        name: row.name,
        greeting: row.greeting,
        alternativeGreetings: row.alternative_greetings,
        avatar,
        banner,
        isNSFW: row.is_nsfw,
        isForcedNSFW: !!row.is_forced_nsfw,
        isPrivate: row.is_private,
        isDeleted: !!row.deleted_at,
        createdBy: row.profiles?.username ?? "Unknown",
        createdAt: row.created_at,
        updatedAt: row.updated_at ?? undefined,
        messageCount: row.total_message_count,
        likeCount: row.total_like_count,
        isLiked,
        externalCreatedBy: row.external_created_by ?? undefined
      };
    };

    if (Array.isArray(value)) {
      const tagIDs = value.flatMap((r) => r.tag_ids);
      const tagMap = await getTagMap(tagIDs);
      return Promise.all(value.map((r) => process(tagMap, r)));
    }
    const tagMap = await getTagMap(value.tag_ids);
    return process(tagMap, value);
  }
}
