import { ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

type DeepPartial<TObject> = TObject extends object
  ? {
      [P in keyof TObject]?: DeepPartial<TObject[P]>;
    }
  : TObject;

export function cn(...inputs: ClassValue[]): string {
  return twMerge(clsx(inputs));
}

/**
 * Check if the given value is of type Error, if not rethrow it.
 * @param error A value that should be of type Error
 */
export function isError(error: any): asserts error is Error {
  if (!(error instanceof Error)) {
    throw error;
  }
}

export function throttle(fn: (...args: any[]) => void, ms: number) {
  let lastTime = 0;
  let timeout: any;

  return function executedFunction(...args: any[]) {
    const now = Date.now();
    const remaining = ms - (now - lastTime);

    if (remaining <= 0) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      lastTime = now;
      fn(...args);
    } else if (!timeout) {
      timeout = setTimeout(() => {
        lastTime = Date.now();
        timeout = null;
        fn(...args);
      }, remaining);
    }
  };
}

/**
 * Freeze an object along with all of it's properties and subproperties making it completely immutable.
 * This is useful because Object.freeze() only freezes the top level properties.
 * @param object The object to freeze
 * @returns The frozen object
 */
export function deepFreeze(object: any) {
  const props = Object.getOwnPropertyNames(object);

  // Iterate through all top level properties
  props.forEach((prop) => {
    const subProp = object[prop];

    // Recursively traverse sub-properties
    if (subProp && typeof subProp === "object") {
      deepFreeze(subProp);
    }
  });

  // Freeze self
  return Object.freeze(object);
}

/**
 * Remove all top level properties matching the blacklist from an object
 * @param obj The object to clone and remove properties from
 * @param blacklist A string array of properties to remove
 * @returns A new object with the properties removed
 */
export function omit(obj: any, blacklist: string[]): any {
  const deepClone = JSON.parse(JSON.stringify(obj));

  // Delete all the top level properties that are in the blacklist
  Object.getOwnPropertyNames(deepClone).forEach((prop) => {
    if (blacklist.includes(prop)) {
      delete deepClone[prop];
    }
  });

  return deepClone;
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function clamp(val: number, min: number, max: number) {
  return Math.min(Math.max(val, min), max);
}

// Valid file name is unicode letters, numbers, spaces, hyphens, and underscores.
// This is safe against path traversal
export function isValidFileName(name: string): boolean {
  return /^[\p{L}\p{N}_ -]+$/u.test(name);
}

export function spacesToHyphens(str: string) {
  return str.toLowerCase().replace(/\s/g, "-");
}

export function getFileExtension(fileName: string): string | undefined {
  return fileName.split(".").pop() || undefined;
}

export const supportedImageExts = ["png", "jpg", "jpeg", "webp"];
export const supportedImageExtsWithDot = supportedImageExts.map((ext) => `.${ext}`);
export const supportedImageExtsAccepts = supportedImageExtsWithDot.join(",");

export function dbgQuery(query: string, params: any[]): string {
  let i = 0;
  return query.replace(/\?/g, () => {
    if (i >= params.length) {
      throw new Error("Not enough parameters provided for the query");
    }
    const param = params[i++];
    // Assuming all parameters are strings and need to be quoted
    return typeof param === "string" ? `'${param}'` : param;
  });
}

export function isObject(item: any): item is object {
  return item && typeof item === "object" && !Array.isArray(item);
}

/**
 * Deeply merges two objects.
 *
 * @template T - The type of the target object.
 * @param {T} target - The target object to merge into.
 * @param {DeepPartial<T>} source - The source object to merge from.
 * @returns {T} - The merged object.
 */
export function deepMerge<T extends object>(target: T, source: DeepPartial<T>): T {
  if (!target) return source as any;
  const output = JSON.parse(JSON.stringify(target));

  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      const targetValue = (target as any)[key];
      const sourceValue = (source as any)[key];

      if (isObject(sourceValue)) {
        if (!(key in target)) {
          (output as any)[key] = sourceValue;
        } else {
          (output as any)[key] = deepMerge(targetValue, sourceValue);
        }
      } else {
        (output as any)[key] = sourceValue;
      }
    });
  }
  return output;
}
