// @ts-ignore
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { AppRouter } from "@api/trpc/router";
import { createTRPCClient, httpBatchLink } from "@trpc/client";
import { config } from "@web/lib/config";
import superjson from "superjson";
import { sb } from "./supabase";

export const client = createTRPCClient<AppRouter>({
  links: [
    httpBatchLink({
      url: config.apiURL + "/trpc",
      transformer: superjson,
      async headers() {
        const token = (await sb.auth.getSession()).data.session?.access_token;
        console.log("trpc headers", token);
        return token ? { Authorization: `Bearer ${token}` } : {};
      }
    })
  ]
});
export type Client = typeof client;
