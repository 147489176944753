/** 
  @file Data fetching for the Persona resource.
*/

import { config } from "@moe/priv/config";
import { Persona, PersonaInsert, PersonaUpdate } from "@moe/priv/model/persona";
import { TablesInsert, TablesUpdate } from "@moe/priv/types/sb-types";
import { QueryData } from "@supabase/supabase-js";
import { ID } from "@web/accessor/base";
import { sb } from "@web/lib/supabase";
import { removeImage, uploadImage } from "@web/lib/utils";

/** The (R)esource type */
type R = Persona;

/** The type used for (I)nserts, defaultable fields are optional.  */
type I = PersonaInsert;

/** The type used for (U)pdates, all fields are optional.  */
type U = PersonaUpdate;

namespace Q {
  export namespace All {
    export const selector = `id, name, description, avatar_file_name, banner_file_name, is_default`;
    const builder = sb.from("personas").select(selector);
    export type Builder = typeof builder;
    export type Data = QueryData<typeof builder>[number];
  }
}

export class PersonaAccessor {
  private readonly userID: string | undefined;

  constructor({ userID }: { userID?: string } = {}) {
    this.userID = userID;
  }

  async getByID(id: ID): Promise<R>;
  async getByID(id: ID[]): Promise<R[]>;
  async getByID(id: ID | ID[]): Promise<R | R[]>;
  async getByID(id: ID | ID[]): Promise<R | R[]> {
    const q = sb.from("personas").select(Q.All.selector);
    if (Array.isArray(id)) {
      const { data, error } = await q.in("id", id);
      if (error) throw error;
      return this.processAllQuery(data);
    }
    const { data, error } = await q.eq("id", id).single();
    if (error) throw error;
    return this.processAllQuery(data);
  }

  async create(value: I): Promise<R>;
  async create(value: I[]): Promise<R[]>;
  async create(value: I | I[]): Promise<R | R[]>;
  async create(value: I | I[]): Promise<R | R[]> {
    if (Array.isArray(value)) return Promise.all(value.map((v) => this.create(v)));
    const avatar = value.avatar;
    const banner = value.banner;

    let avatarFileName: string | undefined;
    let bannerFileName: string | undefined;

    if (avatar) avatarFileName = await uploadImage(avatar);
    if (banner) bannerFileName = await uploadImage(banner);
    const insertData: TablesInsert<"personas"> = {
      name: value.name,
      description: value.description,
      avatar_file_name: avatarFileName,
      banner_file_name: bannerFileName,
      is_default: value.isDefault
    };

    const { data, error } = Array.isArray(value)
      ? await sb.from("personas").insert(insertData).select(Q.All.selector)
      : await sb.from("personas").insert(insertData).select(Q.All.selector).single();
    if (error) throw error;
    return this.processAllQuery(data);
  }

  async update(value: U): Promise<R>;
  async update(value: U[]): Promise<R[]>;
  async update(value: U | U[]): Promise<R | R[]>;
  async update(value: U | U[]): Promise<R | R[]> {
    // Make multiple calls to update each row (The Supabase client does not support bulk updates)
    if (Array.isArray(value)) return Promise.all(value.map(async (v) => this.update(v)));
    // Get the current avatar and banner file names
    const { data: old, error: oldError } = await sb
      .from("personas")
      .select("avatar_file_name, banner_file_name")
      .eq("id", value.id)
      .single();
    if (oldError) throw oldError;

    const avatar = value.avatar;
    const banner = value.banner;
    let avatarFileName: string | undefined;
    let bannerFileName: string | undefined;
    if (avatar) avatarFileName = await uploadImage(avatar);
    if (banner) bannerFileName = await uploadImage(banner);

    const updateValue: TablesUpdate<"personas"> = {
      name: value.name,
      description: value.description,
      avatar_file_name: avatarFileName,
      banner_file_name: bannerFileName,
      is_default: value.isDefault
    };

    const { data, error } = await sb
      .from("personas")
      .update(updateValue)
      .eq("id", value.id)
      .select(Q.All.selector)
      .single();
    if (error) throw error;

    // Remove old files only if new files were uploaded
    if (old.avatar_file_name && avatarFileName) await removeImage(old.avatar_file_name);
    if (old.banner_file_name && bannerFileName) await removeImage(old.banner_file_name);

    return this.processAllQuery(data);
  }

  async remove(id: ID | ID[]): Promise<void> {
    if (!Array.isArray(id)) id = [id];
    await sb.from("personas").update({ deleted_at: new Date().toISOString() }).in("id", id);
  }

  async getByChatID(chatID: ID): Promise<R> {
    const { data, error } = await sb
      .from("chats")
      .select(`personas!inner (${Q.All.selector})`)
      .eq("id", chatID)
      .single();
    if (error) throw error;
    return this.processAllQuery(data.personas);
  }

  /**
   * Retrieves the active personas of the current user.
   *
   * @returns A promise that resolves to the active personas of the current user.
   */
  async getSelf(): Promise<R[]> {
    if (!this.userID) throw new Error("User is not signed in.");
    const { data, error } = await sb
      .from("personas")
      .select(Q.All.selector)
      .eq("created_by", this.userID)
      .is("deleted_at", null)
      .order("created_at");
    if (error) throw error;
    return this.processAllQuery(data);
  }

  /**
   * Retrieves the default personas.
   */
  async getDefault(): Promise<R | undefined> {
    const { data, error } = await sb
      .from("personas")
      .select(Q.All.selector)
      .eq("is_default", true)
      .is("deleted_at", null)
      .maybeSingle();
    if (error) throw error;

    if (data) return this.processAllQuery(data);
    return undefined;
  }

  private processAllQuery(value: Q.All.Data[]): R[];
  private processAllQuery(value: Q.All.Data): R;
  private processAllQuery(value: Q.All.Data | Q.All.Data[]): R | R[];
  private processAllQuery(value: Q.All.Data | Q.All.Data[]): R | R[] {
    if (Array.isArray(value)) return value.map((r) => this.processAllQuery(r));
    let avatar: URL | undefined;
    let banner: URL | undefined;

    if (value.avatar_file_name)
      avatar = new URL(
        sb.storage.from(config.storage.publicImagesBucket).getPublicUrl(value.avatar_file_name).data.publicUrl
      );
    if (value.banner_file_name) {
      banner = new URL(
        sb.storage.from(config.storage.publicImagesBucket).getPublicUrl(value.banner_file_name).data.publicUrl
      );
    }
    return {
      id: value.id,
      name: value.name,
      description: value.description,
      avatar,
      banner,
      isDefault: value.is_default
    };
  }
}
