import { ProfileSettings } from "@moe/priv/model/profile";
import { Session } from "@supabase/supabase-js";
import { useQuery } from "@tanstack/react-query";
import { sb } from "@web/lib/supabase";
import { queryClient } from "@web/routes/__root";

// TODO: this should manage the fetching of all user preferences including: api, theme, text colors, etc

export const settingsQK = "settings";

export function useSettingsQuery(session: Session | undefined) {
  const { data: settings } = useQuery(
    {
      queryKey: [settingsQK, session?.user.id],
      queryFn: async () => {
        const defaultSettings: ProfileSettings = {
          nsfwOK: false,
          messageAvatarSize: "md",
          messageAvatarShape: "circle"
        };
        const id = session?.user.id;
        if (!id) return defaultSettings;
        const { data, error } = await sb.from("profile_settings").select("*").eq("id", id).single();
        if (error) return;
        return {
          nsfwOK: data.nsfw_ok,
          messageAvatarSize: data.message_avatar_size,
          messageAvatarShape: data.message_avatar_shape
        } satisfies ProfileSettings;
      }
    },
    queryClient
  );

  return settings;
}
