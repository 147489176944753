if (__VERCEL_ENV__ !== "development" && __VERCEL_ENV__ !== "preview" && __VERCEL_ENV__ !== "production") {
  console.warn(`__VERCEL_ENV__ is not one of 'development', 'preview', or 'production'. Got: ${__VERCEL_ENV__}`);
}

export const config = {
  apiURL: import.meta.env.VITE_API_URL as string,
  rootURL: import.meta.env.VITE_VERCEL_URL as string,
  supabaseURL: import.meta.env.VITE_SUPABASE_URL as string,
  supabaseAnonKey: import.meta.env.VITE_SUPABASE_ANON_KEY as string,
  env: {
    dev: __VERCEL_ENV__ === "development",
    preview: __VERCEL_ENV__ === "preview",
    prod: __VERCEL_ENV__ === "production"
  }
};
