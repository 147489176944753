import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@moe/oss/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@moe/oss/ui/card";
import { Checkbox } from "@moe/oss/ui/checkbox";
import { DiscordIcon } from "@moe/oss/ui/discord-icon";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@moe/oss/ui/form";
import { GoogleIcon } from "@moe/oss/ui/google-icon";
import { Input } from "@moe/oss/ui/input";
import { Link } from "@tanstack/react-router";
import { config } from "@web/lib/config";
import { sb } from "@web/lib/supabase";
import { useAppContext } from "@web/route-services/root/AppContext";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

export type FormKind = "signin" | "signup";

export const passwordSchema = z
  .string()
  .min(8, { message: "Password must be at least 8 characters long" })
  .max(64, { message: "Password cannot exceed 64 characters" });
// This seems to lag the authform on mobile
// .refine((password) => /[A-Z]/.test(password), {
//   message: "Password must contain at least one uppercase letter"
// })
// .refine((password) => /[a-z]/.test(password), {
//   message: "Password must contain at least one lowercase letter"
// })
// .refine((password) => /[0-9]/.test(password), { message: "Password must contain at least one number" })
// .refine((password) => /[!@#$%^&*()_+-=[\]{};':"|<>?,./`~]/.test(password), {
//   message: "Password must contain at least one special character (!@#$%^&*()_+-=[]{};':\"|<>?,./`~)"
// });

const schema = z.object({
  email: z.string().email(),
  password: passwordSchema,
  age: z.boolean().default(false)
});

type FormSchema = z.infer<typeof schema>;

interface Props {
  formKind?: FormKind;
}

export function AuthForm({ formKind = "signin" }: Props) {
  const [kind, setKind] = useState<FormKind>(formKind);
  const form = useForm<FormSchema>({
    resolver: zodResolver(schema),
    defaultValues: {
      email: "",
      password: "",
      age: false
    }
  });

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={kind}
        initial={{ opacity: 0.3, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -15 }}
        transition={{ duration: 0.1 }}
      >
        <Card className="text-tx-primary mx-auto w-full max-w-lg border-none bg-transparent md:w-96">
          <Header kind={kind} />
          <Content kind={kind} form={form} />
          <Footer kind={kind} setKind={setKind} />
        </Card>
      </motion.div>
    </AnimatePresence>
  );
}
function Header({ kind }: { kind: FormKind }) {
  const formActionText = kind === "signin" ? "Login" : "Sign Up";
  async function signInWithGoogle() {
    const { error } = await sb.auth.signInWithOAuth({
      provider: "google",
      options: {
        redirectTo: config.rootURL
      }
    });

    if (error) {
      toast.error(error.message);
      console.error("Sign In With Google Error:", error);
      return;
    }
  }

  async function signInWithDiscord() {
    const { error } = await sb.auth.signInWithOAuth({
      provider: "discord",
      options: {
        redirectTo: config.rootURL
      }
    });
    if (error) {
      toast.error(error.message);
      console.error("Sign In With Discord Error:", error);
      return;
    }
  }
  return (
    <CardHeader>
      <CardTitle className="text-2xl">{formActionText}</CardTitle>
      <CardDescription className="text-tx-tertiary font-semibold">
        {kind === "signin" ? "Login to your existing account" : "Create a new account"}
      </CardDescription>
      <div className="flex flex-col space-y-2">
        <Button
          className="bg-accent text-tx-primary flex w-full flex-row space-x-4 font-medium"
          onClick={signInWithGoogle}
        >
          <p>{formActionText} with Google</p>
          <GoogleIcon className="" />
        </Button>
        <Button
          className="bg-accent text-tx-primary flex w-full flex-row space-x-4 font-medium"
          onClick={signInWithDiscord}
        >
          <p>{formActionText} with Discord</p>
          <DiscordIcon className="fill-tx-primary " />
        </Button>
      </div>
    </CardHeader>
  );
}

function Content({ kind, form }: { kind: FormKind; form: UseFormReturn<FormSchema> }) {
  const { closeModal } = useAppContext();
  const formActionText = kind === "signin" ? "Login" : "Sign Up";
  const autocomplete =
    kind === "signin"
      ? {
          username: "username",
          password: "current-password"
        }
      : {
          username: "username",
          password: "new-password"
        };

  const onSubmit = async (data: FormSchema) => {
    const { email, password } = data;
    if (kind === "signin") {
      await signInWithEmail(email, password);
      return;
    }
    const age = form.getValues("age");
    if (!age) {
      toast.error("You must be at least 18 years old to use this service.");
      closeModal();
      return;
    }
    await signUpWithEmail(email, password);
  };

  const signInWithEmail = async (email: string, password: string) => {
    const { error } = await sb.auth.signInWithPassword({ email, password });
    if (error) {
      toast.error(error.message);
      console.error("Sign In Error:", error);
      return;
    }
    closeModal();
  };

  async function resetPasswordHandler(email: string) {
    const { error } = await sb.auth.resetPasswordForEmail(email, {
      redirectTo: config.rootURL
    });
    if (error) {
      toast.error(error.message);
      console.error("Password Reset Error:", error);
      return;
    }
    toast.success("Password reset email sent!");
  }

  const signUpWithEmail = async (email: string, password: string) => {
    const { error } = await sb.auth.signUp({
      email,
      password,
      options: {
        emailRedirectTo: config.rootURL
      }
    });
    if (error) {
      toast.error(error.message);
      console.error("Sign Up Error:", error);
      return;
    }
    toast.success("Signed up successfully! Please check your email to confirm your account.", {
      duration: 20000
    });
    closeModal();
  };

  return (
    <CardContent className="border-0 px-6 pb-2 pt-0">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="grid gap-4" noValidate>
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input
                    className="bg-input-primary text-tx-primary"
                    placeholder="Your email address"
                    autoComplete={autocomplete.username}
                    type="email"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <div className="flex items-center">
                  <FormLabel>Password</FormLabel>
                </div>
                <FormControl>
                  <Input
                    placeholder="Your password"
                    autoComplete={autocomplete.password}
                    className="bg-input-primary text-tx-primary"
                    type="password"
                    {...field}
                  />
                </FormControl>
                {kind === "signin" && (
                  <Link
                    className="text-tx-secondary ml-auto inline-block text-sm underline"
                    onClick={() => {
                      const email = form.getValues("email");
                      if (!email) toast.error("Please enter your email first.");
                      resetPasswordHandler(email);
                    }}
                  >
                    Forgot your password?
                  </Link>
                )}
                <FormMessage />
              </FormItem>
            )}
          />

          {kind === "signup" && (
            <FormField
              control={form.control}
              name="age"
              render={({ field }) => (
                <FormItem className="">
                  <div className="my-3 flex items-center space-x-2">
                    <FormControl>
                      <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                    </FormControl>
                    <FormLabel className="text-tx-primary font-semibold">CONFIRM YOU ARE 18+</FormLabel>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
          <Button className="bg-action-primary text-tx-primary w-full font-semibold" type="submit">
            {formActionText}
          </Button>
        </form>
      </Form>
    </CardContent>
  );
}

function Footer({ kind, setKind }: { kind: FormKind; setKind: React.Dispatch<React.SetStateAction<FormKind>> }) {
  return (
    <CardFooter className="text-tx-secondary mt-4 flex flex-col justify-center space-y-3 pt-0 text-center text-sm font-medium">
      {kind === "signin" && (
        <div>
          Don't have an account?{" "}
          <button className="text-action-primary font-semibold underline" onClick={() => setKind("signup")}>
            Sign up
          </button>
        </div>
      )}
      {kind === "signup" && (
        <div className="flex flex-col items-center space-y-4">
          <p className="text-tx-secondary text-sm">
            By continuing you accept our{" "}
            <Link to="/tos" className="text-action-primary underline">
              terms of service
            </Link>{" "}
            and acknowledge our{" "}
            <Link to="/privacy" className="text-action-primary underline">
              privacy policy
            </Link>
            .
          </p>
          <div className="bg-tx-tertiary h-[1px] w-1/2 opacity-50"></div>
          <div>
            Already have an account?{" "}
            <button className="text-action-primary font-semibold underline" onClick={() => setKind("signin")}>
              Login
            </button>
          </div>
        </div>
      )}
    </CardFooter>
  );
}
