import { Toaster } from "@moe/oss/ui/sonner";
import { TooltipProvider } from "@moe/oss/ui/tooltip";
import { toolTipDelay } from "@moe/priv/components/tip";
import { createIDBPersister } from "@moe/priv/idb";
import { DialogConfig } from "@moe/priv/types/types";
import { OmitKeyof, Query, QueryClient, QueryClientConfig, QueryClientProvider, QueryKey } from "@tanstack/react-query";
import { PersistQueryClientOptions, PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createRootRoute, Outlet, useNavigate } from "@tanstack/react-router";
import { sb } from "@web/lib/supabase";
import { AppContextProvider } from "@web/route-services/root/AppContext";
import { DevTools } from "@web/route-services/root/DevTools";
import { DialogProvider } from "@web/route-services/root/DialogProvider";
import { ModalProvider } from "@web/route-services/root/ModalProvider";
import { useApplyUserPreference } from "@web/route-services/root/use-apply-user-preference";
import { useEffect, useState } from "react";

const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retryDelay: (attemptIndex: number) => Math.min(2500 * 2 ** attemptIndex, 30000),
      retry: (failureCount) => {
        return failureCount < 3;
      }
    }
  }
};

export const queryClient = new QueryClient(queryClientConfig);

const persistOptions: OmitKeyof<PersistQueryClientOptions, "queryClient"> = {
  persister: createIDBPersister(),
  maxAge: 1000 * 60 * 60 * 24,
  dehydrateOptions: {
    shouldDehydrateQuery: (query: Query<unknown, Error, unknown, QueryKey>) => {
      // Only persist queries that:
      // 1. Have successful status
      // 2. Have persist: true in their metadata
      return query.state.status === "success" && query.meta?.persist === true;
    }
  }
};

export const Route = createRootRoute({ component: RootComponent });
function RootComponent() {
  const navigate = useNavigate();
  const [alertConfig, setAlertConfig] = useState<DialogConfig | undefined>();

  // When resetting password, Supabase sends the user an email with a link
  // When they click on the link they are directed to the app, and a PASSWORD_RECOVERY event is triggered
  // We shuld redirect them to the reset password page
  useEffect(() => {
    sb.auth.onAuthStateChange(async (event, _session) => {
      if (event === "PASSWORD_RECOVERY") {
        navigate({ to: "/settings/reset-password" });
      }
    });
  }, [navigate]);

  useApplyUserPreference();

  return (
    <PersistQueryClientProvider client={queryClient} persistOptions={persistOptions}>
      <QueryClientProvider client={queryClient}>
        <AppContextProvider setAlertConfig={setAlertConfig}>
          <TooltipProvider delayDuration={toolTipDelay}>
            <DialogProvider config={alertConfig} setConfig={setAlertConfig} />
            <ModalProvider />
            <DevTools />
            <div className="text-base">
              <Outlet />
            </div>
            <Toaster position="top-right" />
          </TooltipProvider>
        </AppContextProvider>
      </QueryClientProvider>
    </PersistQueryClientProvider>
  );
}
