import { forwardRef } from "react";
import { cn } from "../utils/utils";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = forwardRef<HTMLInputElement, InputProps>(({ className, type, ...props }, ref) => {
  return (
    <input
      type={type}
      className={cn(
        `border-line bg-input-primary text-tx-primary placeholder:text-tx-tertiary flex h-12 w-full select-text rounded-sm border px-2.5 py-1
        text-base transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium
        placeholder:font-[450] focus:outline-none focus-visible:outline-none disabled:cursor-not-allowed
        disabled:opacity-50`,
        className
      )}
      ref={ref}
      {...props}
    />
  );
});
Input.displayName = "Input";

export { Input };
