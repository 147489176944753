import { useAppContext } from "@web/route-services/root/AppContext";
import React from "react";

export function DevTools() {
  const { isDebug } = useAppContext();

  const ReactQueryDevTools = isDebug
    ? React.lazy(() =>
        import("@tanstack/react-query-devtools/build/modern/production.js").then((d) => ({
          default: d.ReactQueryDevtools
        }))
      )
    : () => null;

  const Cmd = isDebug
    ? React.lazy(() =>
        import("./cmd").then((mod) => ({
          default: mod.Cmd
        }))
      )
    : () => null;

  const TanstackRouterDevTools = isDebug
    ? React.lazy(() =>
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools
        }))
      )
    : () => null;

  if (isDebug) import("eruda").then((eruda) => eruda.default.init());

  return (
    <>
      {/* <React.Suspense fallback={null}>{<ReactQueryDevTools />}</React.Suspense> */}
      <React.Suspense fallback={null}>{<Cmd />}</React.Suspense>
      {/* <React.Suspense fallback={null}>{<TanstackRouterDevTools />}</React.Suspense> */}
    </>
  );
}
