import clsx, { ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

/**
 * A utility function to semantically merge tailwind css class names.
 * Takes in a variable number of parameters, merges the classnames prioritizing later parameters.
 * This is used in ShadCN to provide default styles, and allow callers to override them.
 *
 * @param inputs - An array of CSS class names or values that can be converted to class names.
 * @returns A string containing the combined CSS class names.
 *
 * @example
 * const a = cn("text-black", "bg-white", "text-red-500");
 * // "text-black bg-white text-red-500"
 *
 * cosnt b = cn("text-black", "text-white");
 * // "text-black"
 */
export function cn(...inputs: ClassValue[]): string {
  return twMerge(clsx(inputs));
}

/**
 * Cleans a file path by removing any consecutive forward slashes.
 *
 * @param path - The file path to clean.
 * @returns The cleaned file path.
 */
export function cleanPath(path: string) {
  return path.replace(/\/{2,}/g, "/");
}

/**
 * Joins an array of string paths into a single cleaned path.
 *
 * @param paths - An array of string paths to join.
 * @returns The joined and cleaned path.
 * @example
 * joinPaths("foo", "bar", "baz"); // "foo/bar/baz"
 * joinPaths("foo", "/bar", "baz"); // "foo/bar/baz"
 */
export function joinPaths(...paths: string[]) {
  return cleanPath(
    paths
      .filter((val) => {
        return val !== void 0;
      })
      .join("/")
  );
}

/**
 * Creates an array of elements split into groups the length of size.
 * If array can't be split evenly, the final chunk will be the remaining elements.
 *
 * @param array - The array to process.
 * @param size - The length of each chunk.
 * @returns The new array of chunks.
 */
export function chunk<T>(array: T[], size: number): T[][] {
  if (size <= 0) {
    throw new Error("Size must be a positive integer.");
  }

  const result: T[][] = [];
  let index = 0;

  while (index < array.length) {
    result.push(array.slice(index, index + size));
    index += size;
  }

  return result;
}

/**
 * Creates a deeply readonly version of a type.
 * Recursively makes all properties and nested properties of an object or array readonly.
 *
 * @template T - The type to make deeply readonly
 *
 * @example
 * type Person = {
 *   name: string;
 *   age: number;
 *   address: {
 *     street: string;
 *     city: string;
 *   };
 *   hobbies: string[];
 * };
 *
 * type ReadonlyPerson = DeepReadonly<Person>;
 * // Result:
 * // {
 * //   readonly name: string;
 * //   readonly age: number;
 * //   readonly address: {
 * //     readonly street: string;
 * //     readonly city: string;
 * //   };
 * //   readonly hobbies: readonly string[];
 * // }
 */
export type DeepReadonly<T> = T extends (infer R)[]
  ? ReadonlyArray<DeepReadonly<R>>
  : T extends (...args: any[]) => any
    ? T
    : T extends object
      ? { readonly [P in keyof T]: DeepReadonly<T[P]> }
      : T;

export function debounce(fn: (...args: any[]) => void, ms: number) {
  let timeout: ReturnType<typeof setTimeout> | undefined;

  return function wrapped(...args: any[]) {
    const later = () => {
      clearTimeout(timeout);
      fn(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, ms);
  };
}

export interface Names {
  char: string;
  user: string;
}
/**
 * Replaces {{char}} and {{user}} in a string with the corresponding values.
 * The char and user placeholders are case insensitive.
 * This will templates both triple and double curly braces.
 *
 * @param template - The template string potentially containing {{char}} and {{user}}.
 * @param values - An object containing the values to replace the placeholders.
 * @returns The templated string.
 *
 */
export function templateNames(template: string, values: Names): string {
  return template.replace(/\{\{(char|user)\}\}|\{\{\{(char|user)\}\}\}/gi, (match, p1, p2) => {
    const key = (p1 || p2).toLowerCase();
    return (values[key as keyof Names] as string) || match;
  });
}

/**
 * Merges two deep mergeable objects (objects or arrays).
 * Only merges the source fields into the target if the source field is truthy.
 *
 * This is often useful to merge partial configs the caller passed in with default configs.
 *
 * @param target - The target object to merge into.
 * @param source - The source object to merge.
 * @returns The merged object.
 *
 */
export function mergeSkipFalsy<T>(target: T, source: any): T {
  if (!target) return source;
  if (!source) return target;

  // Array case
  if (Array.isArray(target)) {
    if (source) return source;
    return target;
  }

  // Object case
  const merged: any = { ...target };

  for (const key in source) {
    const sourceValue = source[key];
    const targetValue = (target as any)[key];

    // If source is truthy, merge source to target
    if (sourceValue) {
      merged[key] = sourceValue;
      continue;
    }

    // If both values are objects, recursively merge
    if (
      typeof sourceValue === "object" &&
      sourceValue !== null &&
      typeof targetValue === "object" &&
      targetValue !== null
    ) {
      merged[key] = mergeSkipFalsy(targetValue, sourceValue);
      continue;
    }

    // Keep target value in all other cases
    merged[key] = targetValue;
  }

  return merged;
}

/**
 * Global definition of the number of character per LLM (generic) token.
 * This is only an estimate.
 */
export const charPerToken = 4;

/**
 * Removes any hashtags (words starting with '#') from the input string.
 * @param input - The input string to remove hashtags from.
 * @returns The input string with all hashtags removed.
 */
export const removeHashtags = (input: string) => {
  // Split into words and filter out words starting with #
  return input
    .split(" ")
    .filter((word) => !word.startsWith("#"))
    .join(" ")
    .trim();
};
